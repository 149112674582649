.tipsters {
    &__card {
        &#{--option} {
            box-shadow: 0 .75rem 2rem rgba($color: $primary, $alpha: .25);

            .card-header {
                color: $white;

                p {
                    text-align: center;
                }

                span {
                    font-weight: bold;
                }
            }

            .card-title {
                background-color: rgba($color: $primary-light, $alpha: .35);
                border-bottom: solid 1px rgba($color: $primary, $alpha: .45);

                .title__pop {
                    font-size: 2rem;
                    text-shadow: 
                        0px 4px 0px $white, 
                        1px 5px 0px $primary, 
                        1px 8px 0px $white, 
                        1px 9px 0px $primary;
                }
            }

            .card-text {
                span {
                    font-weight: bold;
                }
            }
        }
    }    
}
