.step-box {
    position: relative;
    width: 265px;
    display: flex;

    .content {
        position: absolute;
        right: -20px;
        bottom: -45px;
        
        &#{--1} {
            @extend .content;
            left: 80px;

            @media screen and (max-width: 768px) {
                right: 0px;
            }
        }

        &#{--2} {
            @extend .content;
            left: 125px;
            right: -70px;

            @media screen and (max-width: 768px) {
                right: 0px;
            }
        }

        @media screen and (max-width: 768px) {
            bottom: auto;
        }
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        align-items: center;
    }
}
