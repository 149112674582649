.home-content {
    .feedback {
        &__title-section {
            margin-bottom: 200px;
        }
    }

    .carousel-row {
        min-height: 300px;
        padding-bottom: 14rem !important;
    }

    .feedback {

        .swiper{
            &.swiper-initialized {
                .swiper-wrapper {
                    margin-top: 10rem;
                    margin-bottom: 4rem;
                }

                .swiper-slide {
                    // position: relative;

                    .feedback__card {
                        margin-left: -85px;
                        margin-right: -85px;
                        scale: .85;
                        z-index: -1;
                        display: flex;
                        align-items: flex-end;
                        transition: all .236s ease-in-out;
                        
                        .card {
                            box-shadow: 0 0.5rem 1rem rgba($color: $primary, $alpha: .2);
                        }

                        @media screen and (max-width: 992px) {
                            margin-left: 10px;
                            margin-right: 10px;
                        }
                    }

                    &#{-active} {    
                        z-index: 2;
    
                        .card {
                            translate: 0px -90px;
                            scale: 1.2;
                            visibility: visible;
                            box-shadow: 0 1rem 3rem rgba($color: $primary, $alpha: .3);

                            @media screen and (max-width: 992px) {
                                scale: 1;
                            }
                        }
                    }
                }

                .swiper-button-prev, .swiper-button-next {
                    top: 90%;
                    color: $primary;
                    width: 55px;
                    height: 55px;
                    border-radius: 50%;
                    border: solid 1px $dark;

                    &::after {
                        font-size: 25px;
                    }
                }

                .swiper-button-prev {
                    left: 35%;

                    @media screen and (max-width: 500px) {
                        left: 10%;
                    }
                }

                .swiper-button-next {
                    right: 35%;

                    @media screen and (max-width: 500px) {
                        right: 10%;
                    }
                }
            }
        }

        &__card {
            padding: 20px;

            .img-box {
                padding: 1px;
                border-radius: 50%;
                border: solid 1px $gray-300;
            }

            .card-text {
                font-size: 14px;

                span {
                    font-weight: 500;
                    color: $primary;
                    font-size: 14px;
                }
            }
        }
    }
}
