$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='black'/></svg>");

body, #root {
    overflow-x: hidden;
}

h1, h2, h3, h4, h5, p {
    font-family: 'Montserrat';
}

.title {
    font-family: 'Montserrat';

    &__pop {
        font-size: 7rem;
        font-weight: bold;
        font-style: italic;
        text-align: center;
        text-transform: uppercase;
        text-shadow: 
            3px 7px 0px $white,
            4px 9px 0px $primary,
            4px 16px 0px $white,
            5px 18px 0px $primary;

        @media screen and (max-width: 992px) {
            font-size: 4rem;
            text-shadow: 
                1px 5px 0px $white, 
                2px 6px 0px $primary, 
                2px 14px 0px $white, 
                3px 15px 0px $primary;
        }

        @media screen and (max-width: 576px) {
            font-size: 3.5rem;
        }

        @media screen and (max-width: 400px) {
            font-size: 2.95rem;
            text-shadow: 
                0px 4px 0px $white, 
                1px 5px 0px $primary, 
                1px 12px 0px $white, 
                1px 13px 0px $primary;
        }

        &#{--semi} {
            @extend .title__pop;

            text-shadow: 
                3px 7px 0px rgb(250 213 220),
                4px 9px 0px $primary,
                4px 16px 0px rgb(250 213 220),
                5px 18px 0px $primary;

            @media screen and (max-width: 992px) {
                font-size: 4rem;
                text-shadow: 
                    1px 5px 0px rgb(250 213 220), 
                    2px 6px 0px $primary, 
                    2px 14px 0px rgb(250 213 220), 
                    3px 15px 0px $primary;
            }

            @media screen and (max-width: 576px) {
                font-size: 3.5rem;
            }

            @media screen and (max-width: 400px) {
                font-size: 2.95rem;
                text-shadow: 
                    0px 4px 0px rgb(231 55 88 / 21%), 
                    1px 5px 0px $primary, 
                    1px 12px 0px rgb(231 55 88 / 21%), 
                    1px 13px 0px $primary;
            }
        }

        &#{--xsmall} {
            @extend .title__pop;
            font-size: 1.75rem;
            text-shadow: 
                1px 3px 0px $white,
                2px 4px 0px $primary,
                3px 7px 0px $white,
                4px 8px 0px $primary;

            &.transparent-shadow {
                text-shadow: 
                1px 3px 0px rgba($color: $primary, $alpha: .01),
                2px 4px 0px rgba($color: $white, $alpha: .2),
                3px 7px 0px rgba($color: $primary, $alpha: .01),
                4px 8px 0px rgba($color: $white, $alpha: .2);
            }
        }

        &#{--small} {
            @extend .title__pop;
            font-size: 3rem;

            text-shadow: 
                1px 3px 0px $white,
                2px 4px 0px $primary,
                3px 8px 0px $white,
                3px 9px 0px $primary;

            @media screen and (max-width: 992px) {
                font-size: 3rem;
                text-shadow: 
                    1px 5px 0px $white, 
                    2px 6px 0px $primary, 
                    2px 10px 0px $white, 
                    3px 11px 0px $primary;
            }

            @media screen and (max-width: 576px) {
                font-size: 2.75rem;
            }

            @media screen and (max-width: 400px) {
                font-size: 2.95rem;
                text-shadow: 
                    0px 4px 0px $white, 
                    1px 5px 0px $primary, 
                    1px 12px 0px $white, 
                    1px 13px 0px $primary;
            }
        }


        &#{--huge} {
            @extend .title__pop;
            font-size: 10rem;
        }
    }

    span {
        color: $primary;
    }
}

span.accent {
    color: $primary;
}

.btn {
    &.is-active {
        background-color: $dark;
        color: $white;
        border: solid 1px $dark;
    }
}

input {
    &:focus {
        box-shadow: 0 0 0 .1rem rgba($color: $primary, $alpha: .5) !important;
    }
}

.form-switch {
    input[type="checkbox"] {
        width: 2.25em;
        height: 1.25em;
        border-color: $primary;

        &:not(:checked) {
            background-image: $form-switch-bg-image;
        }
        
        &:checked {
            background-color: $black;
            border-color: $black;
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='white'/></svg>") !important;
        }
        
        &:focus {
            box-shadow: none;
        }

        &.huge  {
            width: 3.25em;
            height: 1.75em;
        }

        &.vertical  {
            position: absolute;
            top: 10px;
            left: 30px;
            transform: rotate(90deg);
        }
    }
}

.form-control {
    border-radius: 1.25rem;
}

.input-group-lg {
    .btn {
        border-radius: 1.25rem;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .form-control {
        border-radius: 1.25rem;
        &:first-child {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
        
        &:last-child {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }
    }

}

ul.nav {
    li {
        a {
            color: $dark;

            &:hover {
                color: lighten($color: $dark, $amount: 20);
            }
        }
    }
}

.table {
    thead {
        th {
            background-color: $primary;
        }

        tr th:first-child {
            border-top-left-radius: 20px;
        }

        tr th:last-child {
            border-top-right-radius: 20px;
        }
    }
}
