header {
    z-index: 1030;
}

/* Nav Toggler */
.input-toggler {
    display: none;
}

.menu-toggler {
    position: relative;
    width: 55px;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    cursor: pointer;
    margin-left: 10px;

    @include media-breakpoint-down(sm) {
        width: 45px;
    }
}

.menu-toggler-line {
    width: 90%;
    height: 4px;
    border-radius: 20px;
    background: #000;
    margin: 4px 0 6px 0px;
    position: relative;
    transition: all .40s ease-out;
}

.menu-toggler-line:nth-child(3) {
    width: 60%;
}

.input-toggler:hover~.menu-toggler .menu-toggler-line:nth-child(3) {
    width: 90%;
}

.input-toggler:checked~.menu-toggler .menu-toggler-line {
    top: 5px;
    translateY: 100px;
    transform: rotate(45deg);
    background: #fff;
}

.input-toggler:checked~.menu-toggler .menu-toggler-line:nth-child(2) {
    display: none;
}

.input-toggler:checked~.menu-toggler .menu-toggler-line:nth-child(3) {
    top: -5px;
    translateY: 10;
    transform: rotate(135deg);
    background: #fff;
    width: 90%;
}

.nav {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
}

.nav ul {
    width: 100%;
}

.nav ul li {
    width: 100%;
    text-align: center;
}

.nav ul li a {
    display: inline-block;
    font-size: 36px;
    color: #fff;
    text-transform: uppercase;
}

.sidebar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: #000;
    opacity: 0.5;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transform: translateX(-100%);
    transition: all .40s ease-out;
    background-repeat: no-repeat;
    background-size: cover;
}

.sidebar .menu {
    width: 50%;
    height: 100%;
    padding-left: 4.75rem;
    list-style-type: none;
    background: $primary;
    margin: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    @media screen and (max-width: 992px) {
        width: 100%;
        padding-top: 100px;
        padding-left: 1.2rem;
    }

    @media screen and (max-width: 480px) {
        padding-top: 150px;
    }

    @media screen and (max-width: 450px) {
        padding-top: 80px;
        line-height: 2px;
    }
}

.menu li {
    display: flex;
    align-items: center;
}

.menu li .bullet {
    position: absolute;
    width: 30px;
    height: 6px;
    left: 0;
    margin-right: 10px;
    border-radius: 10px;
    background-color: #000;
    -webkit-transition: width .25s ease-in-out;
    transition: width .25s ease-in-out;
}

.menu-link {
    display: flex;
    align-items: center;
    color: white;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 48px;
    line-height: 8vmin;
    padding-left: 35px;
    -webkit-transition: all .25s ease-out;
    transition: all .25s ease-out;
    text-decoration: none;

    @media screen and (max-width: 992px) {
        font-size: 30px;
        line-height: 10vmin;
    }

    @media screen and (max-width: 480px) {
        line-height: 9vmin;
    }

    @media screen and (max-width: 450px) {
        line-height: 40px;
    }
}

.menu-link:hover,
.menu-link:focus,
.menu-link:active {
    color: white;
}

.menu li {
    position: relative;
    display: flex;
    width: fit-content;
}

.menu li:hover .bullet {
    width: 115%;
}

.input-toggler:checked~.sidebar {
    transform: translateX(0%);
    opacity: 1;
}

.navbar-brand img {
    height: 65px;
    -webkit-transition: filter 1.25s ease-in-out;
    transition: filter 1.25s ease-in-out;

    @include media-breakpoint-down(md) {
        height: 45px;
    }
}

.navbar-brand.hover {
    z-index: 1;
}

.navbar-brand.hover img {
    filter: brightness(0) invert(1);
}
