//Main colors
$primary: #E73758;
$black: #000;
$light: #e3e7eb;
$white: #ffffff;
$primary-light: #FCE6EB4D;
$premium: #C8B374;
$list-group-action-active-bg: $primary;

//Custom colors
$custom-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "blue-100": $blue-100,
  "teal-400": $teal-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "premium": $premium,
  "clean-gray": #f2f2f2,
  "primary-light": $primary-light,
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

//Inputs sizing
$input-padding-y-lg: 1.5rem;
$input-padding-x-lg: 1.5rem;
