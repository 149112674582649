.tipsters {
    &__container {
        margin-top: 150px;
        padding-bottom: 8rem;

        @media screen and (max-width: 992px) {
            padding-bottom: 6rem;
        }
    }

    &__card {
        position: relative;
        top: 60px;
        min-height: 390px;
        border-radius: 12px;
        box-shadow: 0 .75rem 2rem rgba($color: $primary, $alpha: .25);

        .card-body {
            padding: 0;
        }

        .background-box {
            position: relative;
            width: 100%;
            height: 260px;
            border-top-left-radius: 12px !important;
            border-top-right-radius: 12px !important;
            background: transparent linear-gradient(60deg, $premium 0%, $dark 100%) 0% 0% no-repeat padding-box;

            .soccer-ball {
                filter: brightness(1) invert(1);
            }

            .dash-bars {
                position: absolute;
                top: -10px;
                left: 0;
                width: 100%;
                padding: 20px 0px;
                border-top: solid 1px #fff;
                border-top-style: dashed;
                border-bottom: solid 1px #fff;
                border-bottom-style: dashed;

                @media screen and (max-width: 992px) {
                    top: 0;
                }
            }

            .months {
                display: flex;
                justify-content: space-between;
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    font-size: .7rem;
                    font-weight: 300;
                    color: #fff;
                }
            }

            @media screen and (max-width: 992px) {
                height: 290px;
            }
        }

        .stats {
            .tipster-main {
                padding: 15px;
                background-color: rgba($color: $primary, $alpha: .1);
                border-right: solid 1px $primary;
            }
        }

        .checks {
            width: 100%;
            list-style-type: none;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin: 0;
            padding: 0px 8px 8px 8px;

            li {
                padding: 4px;
            }
        }

        &#{--free} {
            @extend .tipsters__card;

            .img-box {
                border: solid 2px $primary;
            }

            .background-box {
                background: transparent linear-gradient(60deg, $primary 0%, $dark 100%) 0% 0% no-repeat padding-box;
            }
        }

        @media screen and (max-width: 992px) {
            margin-bottom: 150px;
        }
    }

    &.img-box {
        position: absolute;
        top: -25px;
        width: 135px;
        height: 135px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 2px $premium;
        background-color: #fff;
        z-index: 1;
    }

    .ranked-tipsters {
        margin-top: 175px;
    }
}
