.service {
    &__container {
        margin-top: 10rem;
    }

    &__card {
        height: 250px;

        .img-box {
            position: absolute;
            top: -100px;
            width: 155px;
            height: 155px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $primary;
        }
    
        .card-body {

            .card-text {
                font-size: 18px;
            }

            span {
                color: $primary;
                font-weight: bold;
            }
        }

        @media screen and (max-width: 992px) {
            margin-bottom: 150px;
        }
    }
}
