.faqs-page {
    overflow: hidden;
    .faqs {
        // height: 50vh;
        margin-top: 120px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &__container {
            padding-top: 4rem;
            padding-bottom: 4rem;

            .accordion {
                box-shadow: 0 1rem 3rem rgba($color: $primary, $alpha: .25);
            }
        }

        @media screen and (max-width: 992px) {
            height: auto;
        }
    }
}
