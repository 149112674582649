.error-page {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .error {
        &__container {
            div {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
        }
    }

    @media screen and (max-width: 992px) {
        height: auto;
    }
}
