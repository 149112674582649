.contacto-page {
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;

    .first-content {
        margin-top: 200px;
    }

    .contact-message-title {
        @media screen and (min-width: 992px) {
            width: 75%;
        }
    }

    .under-contact-row {
        min-height: 300px;

        
        .handphone {
            position: absolute;
            left: -55px;
            bottom: 0;
            width: 550px;
            height: auto;
            z-index: 2;

            @media screen and (max-width: 992px) {
                position: relative;
                width: 100%;
                height: auto;
                left: auto;
            }
        }
    }
}
