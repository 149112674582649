.tipster {
    #tipster-filter-form {
        #sport-select {
            min-height: 4.5rem;
            border-radius: 1.25rem;
            border-top-right-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
    
            &:focus {
                box-shadow: none;
            }
        }
    
        #bookie-filter {
            border-bottom-left-radius: 1.25rem;
        }
    }

    .handphone {
        left: -55px;
        bottom: 0;
        width: 550px;
        height: auto;
        z-index: 2;

        @media screen and (max-width: 992px) {
            position: relative;
            width: 100%;
            height: auto;
            left: auto;
        }
    }
}