.subscription {
    &__form {
        .input {
            display: flex;

            input {
                width: 250px;
                height: 50px;
                padding-left: 30px;
                border-top-left-radius: 25px;
                border-bottom-left-radius: 25px;
                border-bottom-right-radius: 0px;
                border-top-right-radius: 0px;

                @media screen and (max-width: 385px) {
                    width: 200px
                }
            }

            button {
                padding-left: 35px;
                padding-right: 35px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 25px;
                border-top-right-radius: 25px;

                @media screen and (max-width: 385px) {
                    padding-left: 25px;
                    padding-right: 25px;
                }
            }
        }
    }
}
