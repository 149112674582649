@import '@splidejs/react-splide/css';

.splide {
    &__slide {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top center;
        }
    }

    &__pagination {
        z-index: 0;

        li {
            button {
                width: 35px;
                height: 4px;
                border-radius: 4px;
                margin: 0 3px;
                background: #fff;
                opacity: 1;

                &.is-active {
                    width: 70px;
                    margin: 0 18px;
                    background-color: $primary;
                }
            }
        }
    }
}
