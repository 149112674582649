.hazte-tipster-page {
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 120px;

    .htipster {        
        .img-box {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .htipster-why {
        background-color: rgba($color: $primary-light, $alpha: .35);

        .step-box {
            width: 75%;
            
            .content {
                &#{--1}{
                    .title {
                        font-size: 25px;
                    }

                    margin-left: 15px;
                }

                &#{--2}{
                    .title {
                        font-size: 25px;
                    }

                    margin-left: 15px;
                }

                &#{--3}{
                    position: relative;
                    top: 65px;

                    .title {
                        font-size: 25px;
                    }

                    margin-left: 25px;
                }

                &#{--4}{
                    position: relative;
                    top: 75px;

                    .title {
                        font-size: 25px;
                    }

                    .title {
                        font-size: 25px;
                    }

                    margin-left: 25px;
                }
            }

            .text {
                font-size: 15px;

                span {
                    font-weight: bold;
                }
            }

            @media screen and (max-width: 900px) {
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 992px) {
        margin-top: 150px;
    }
}
