footer {
    .menu {
        li {
            .bullet {
                width: 15px;
                height: 3px;
                background-color: $primary;
            }

            a {
                padding-left: 20px;
                font-size: 12px;
                line-height: 1.75;
            }
        }

        @media screen and (max-width: 768px) {
            padding: 0px
        }
    }

    .text-footer {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            height: 115px;
            border-left: solid 1px $primary;
            left: 0px;

            @media screen and (max-width: 768px) {
                display: none
            }
        }
    }
}
